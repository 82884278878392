/*
body { 
  background-color: #151e2c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.App {
  text-align: center;
}

.debug
{
  background-color: #ffffff;
}

.loginGroup
{
  background-color: #ffffff;
  margin: 0px auto 0px auto;
  padding: 10px 10px 10px 10px;
  border-radius: 18px;
  width: 398px;
  color: #ffffff;
}

.loginGroup h2
{
   color: #ffffff;
}

@media (prefers-reduced-motion: no-preference) {}

.appDebug
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 20px);
  overflow-x: scroll;
  /* display: none; */
}

.appHeader
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 10px 10px 10px 10px;
}

.appBody
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 10px 10px 10px 10px;
}

.appFooter
{
  background-color: #ffffff;
  margin: 0px auto 10px auto;
  padding: 10px 10px 10px 10px;
}

.appTable
{
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 0px);
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.appFilter
{
  background-color: #ffffff;
  padding: 10px 0px 10px 0px;
  width: calc(100%);
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.appFilter::-webkit-scrollbar
{
  display: none;
}

.error, #loginResult
{
  color: #ff0000;
}

#registerResult {
  color: #ff0000;
}

#mapid
{
  height: 180px;
}

.boutline
{
  background-color: #ff000000 !important;
  border: 1px solid black;
}

.displayNone
{
  display: none;
}

.ui-datatable .ui-column-filter {
  width: 100%;
  box-sizing: border-box;
}

.disabled {
  display: none;
}

.displayNone {
  display: none;
}

.p-menubar-root-list {
  /* margin: 0px auto 0px auto; */
  text-align: center;
  align-items: center;
}

.p-menubar {
  justify-content: space-between;
}
.p-menubar .p-menubar-end {
  margin-left: 0px !important;
}

.p-toast {
  background: #ffffff;
}

.companyImage{
  background: rgba(255,255,255,0.8);
  padding: 5px 5px 5px 5px;
  border: 1px solid rgba(255,255,255,0.8);
}